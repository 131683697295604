import { ImageSource } from "@earthtoday/contract";
import { observer } from "mobx-react-lite";
import Link from "next/link";

import { useTranslation } from "../../shared/translate/NextI18next";
import { sc } from "./CardItemTransactionCollectable.styled";

export interface CardItemTransactionCollectableBottomDriver {
  reserveName: string;
  protectedBy: string;
  donatedBy: string;
  registeredTo: string;
  collectionName: string;
  collectionCreatedBy: string;
  id: string;
  imageSource: typeof ImageSource.UON_IMAGE;

  onGiveBtnClicked(): void;
  isGiftActive: boolean;

  hoverItem?: "VISIT" | "GIVE"; // This prop is for storybook only
  shouldOpenWithBlank?: boolean; // use on certificate
  onVistButtonClicked(): void;
}

interface IProps {
  driver: CardItemTransactionCollectableBottomDriver;
}
export const CardItemTransactionCollectableBottom = observer(
  function CardItemTransactionCollectableBottom(props: IProps) {
    const {
      reserveName,
      donatedBy,
      protectedBy,
      registeredTo,
      onGiveBtnClicked,
      hoverItem,
      isGiftActive,
      collectionName,
      collectionCreatedBy,
      onVistButtonClicked,
    } = props.driver;

    const { t } = useTranslation("CardItemPromotion");

    return (
      <sc.BottomContainer data-cy="CardItemTransactionPromotionBottom-Container">
        <sc.TransactionDetail>
          <sc.ReserveName data-cy="CardItemTransactionPromotionBottom-ReserveName">
            {reserveName}
          </sc.ReserveName>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-ProtectedBy">
            {t("card-transaction-promotion.protected-by", { protectedBy })}
          </sc.BottomText>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-CreatedBy">
            {t("card-transaction-promotion.donated-by", { donatedBy })}
          </sc.BottomText>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-RegisteredTo">
            {t("card-transaction-promotion.registered-to", { registeredTo })}
          </sc.BottomText>
          <sc.CollectionName data-cy="CardItemTransactionPromotionBottom-CollectionName">
            {collectionName}
          </sc.CollectionName>
          <sc.BottomText data-cy="CardItemTransactionPromotionBottom-CollectionCreatedBy">
            {t("card-transaction-promotion.collection-created-By", {
              collectionCreatedBy,
            })}
          </sc.BottomText>
        </sc.TransactionDetail>
        <sc.ButtonsContainer>
          {!isGiftActive && (
            <>
              <Link
                href="/m2/[transactionId]"
                as={`/m2/${props.driver.id}`}
                target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
                passHref
                legacyBehavior
              >
                <sc.VisitBtn
                  data-cy="CardItemTransactionPromotionBottom-VisitButton"
                  className={hoverItem === "VISIT" ? "hover" : ""}
                  target={props.driver.shouldOpenWithBlank ? "_blank" : "_self"}
                  onClick={onVistButtonClicked}
                >
                  {t("card-transaction-promotion.visit-button")}
                </sc.VisitBtn>
              </Link>
              <sc.GiveBtn
                data-cy="CardItemTransactionPromotionBottom-GiveButton"
                className={hoverItem === "GIVE" ? "hover" : ""}
                onClick={onGiveBtnClicked}
              >
                {t("card-transaction-promotion.give-button")}
              </sc.GiveBtn>
            </>
          )}
        </sc.ButtonsContainer>
      </sc.BottomContainer>
    );
  },
);
